import { gql } from '@apollo/client';
import client from '../apolloClient';

const CREATE_SECTION_IMAGE = gql`
  mutation CreateSectionImage($input: CreateSectionImageInput!) {
    createSectionImage(input: $input) {
      id
      promotion_id
      section_number
      section_tag
      url_link
      url_link_alt
      url_link_mobile
      url_link_mobile_alt
      section_button_is_active
      section_button_text
      section_button_url
      section_title
      section_sub_title
      section_selected
      section_button_color
      section_button_font_color
      section_video_url
      section_video_headline
      section_video_description
      section_button_height
      section_button_width
      section_form_slug
      section_form_headline
      section_form_description
      section_form_image_url
      section_form_image_url_alt
      section_form_show_message
      banner_click
      banner_click_url
      is_deleted
      created_at
      updated_at
    }
  }
`;

const UPDATE_SECTION_IMAGE = gql`
  mutation UpdateSectionImage($id: ID!, $input: UpdateSectionImageInput!) {
    updateSectionImage(id: $id, input: $input) {
      id
      promotion_id
      section_number
      created_at
      updated_at
    }
  }
`;

const DELETE_SECTION_IMAGE = gql`
  mutation DeleteSectionImage($id: ID!) {
    deleteSectionImage(id: $id) {
      id
      promotion_id
      section_number
      section_tag
      url_link
      url_link_alt
      url_link_mobile
      url_link_mobile_alt
      section_button_is_active
      section_button_text
      section_button_url
      section_title
      section_sub_title
      section_selected
      section_button_color
      section_button_font_color
      section_video_url
      section_video_headline
      section_video_description
      section_button_height
      section_button_width
      section_form_slug
      section_form_headline
      section_form_description
      section_form_image_url
      section_form_image_url_alt
      section_form_show_message
      banner_click
      banner_click_url
      is_deleted
      created_at
      updated_at
    }
  }
`;

const GET_SECTION_IMAGES = gql`
  query SectionImages {
    sectionImages {
      id
      promotion_id
      section_number
      section_tag
      url_link
      url_link_alt
      url_link_mobile
      url_link_mobile_alt
      section_button_is_active
      section_button_text
      section_button_url
      section_title
      section_sub_title
      section_selected
      section_button_color
      section_button_font_color
      section_video_url
      section_video_headline
      section_video_description
      section_button_height
      section_button_width
      section_form_slug
      section_form_headline
      section_form_description
      section_form_image_url
      section_form_image_url_alt
      section_form_show_message
      banner_click
      banner_click_url
      is_deleted
      created_at
      updated_at
    }
  }
`;

const GET_SECTION_IMAGE = gql`
  query SectionImagesByPromotionId($id: ID!) {
    sectionImagesByPromotionId(promotionId: $id) {
      id
      promotion_id
      section_number
      section_tag
      url_link
      url_link_alt
      url_link_mobile
      url_link_mobile_alt
      section_button_is_active
      section_button_text
      section_button_url
      section_title
      section_sub_title
      section_selected
      section_button_color
      section_button_font_color
      section_video_url
      section_video_headline
      section_video_description
      section_button_height
      section_button_width
      section_form_slug
      section_form_headline
      section_form_description
      section_form_image_url
      section_form_image_url_alt
      section_form_show_message
      banner_click
      banner_click_url
      is_deleted
      created_at
      updated_at
    }
  }
`;

export default {
  get() {
    return client.query({
      query: GET_SECTION_IMAGES,
    });
  },
  create(payload) {
    return client.mutate({
      mutation: CREATE_SECTION_IMAGE,
      variables: { input: payload },
    });
  },
  update(id, payload) {
    return client.mutate({
      mutation: UPDATE_SECTION_IMAGE,
      variables: { id, input: payload },
    });
  },
  getImagesPromotion(id) {
    return client.query({
      query: GET_SECTION_IMAGE,
      variables: { id },
    });
  },
  delete(id) {
    return client.mutate({
      mutation: DELETE_SECTION_IMAGE,
      variables: { id },
    });
  },
};
