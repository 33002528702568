import { gql } from '@apollo/client';
import client from '../apolloClient';



const GET_PROMOTIONS = gql`
  query Promotions($q: String, $status: String, $page: Int, $endDate: String) {
    promotions(filter: { q: $q, status: $status, page: $page,  endDate: $endDate }) {
    promotions {
        id
        title
        is_active
        is_deleted
        button_text
        button_url
        button_is_active
        created_at
        updated_at
        slug
        map_is_active
        latitude
        longitude
        map_title
        desktop_map_media_url
        mobile_map_media_url
        whatsapp_url
        header_url
        whatsapp_text
        is_form_active
        form_media_url
        form_headline
        form_description
        form_button_url
        form_show_success_message
        form_button_text
        is_whatsapp_button_active
        desktop_map_media_url_alt
        mobile_map_media_url_alt
        form_media_url_alt
        is_show_floating_banner
        is_show_newsletter
        slug_is_manual
},
current_page
total_data
total_page
    }
  }
`;

const GET_PROMOTION = gql`
  query GetPromotion($id: ID!) {
    promotion(id: $id) {
      id
        title
        is_active
        is_deleted
        button_text
        button_url
        button_is_active
        created_at
        updated_at
        slug
        map_is_active
        latitude
        longitude
        map_title
        desktop_map_media_url
        mobile_map_media_url
        whatsapp_url
        header_url
        whatsapp_text
        is_form_active
        form_media_url
        form_headline
        form_description
        form_button_url
        form_show_success_message
        form_button_text
        is_whatsapp_button_active
        desktop_map_media_url_alt
        mobile_map_media_url_alt
        form_media_url_alt
        is_show_floating_banner
        is_show_newsletter
        slug_is_manual
    }
  }
`;

const CREATE_PROMOTION = gql`
  mutation CreatePromotion($input: CreatePromotionInput!) {
    createPromotion(input: $input) {
      id
      title
      
    }
  }
`;

const UPDATE_PROMOTION = gql`
  mutation UpdatePromotion($id: ID!, $input: UpdatePromotionInput!) {
    updatePromotion(id: $id, input: $input) {
      id
      title
      form_description
      
      is_active
    }
  }
`;

const UPDATE_PROMOTION_STATUS = gql`
  mutation UpdatePromotionStatus($id: ID!, $is_active: Boolean!) {
    updatePromotionStatus(id: $id, is_active: $is_active) {
      id
      is_active
    }
  }
`;

const DELETE_PROMOTION = gql`
  mutation DeletePromotion($id: ID!) {
    deletePromotion(id: $id) {
      id
    }
  }
`;

const DUPLICATE_PROMOTION = gql`
  mutation DuplicatePromotion($id: ID!) {
    duplicatePromotion(id: $id) {
      id
      title
      is_active
    }
  }
`;

export default {
  get(q, status, page, startDate, endDate, sortBy, sortDir) {
    return client.query({
      query: GET_PROMOTIONS,
      variables: { q, status, page, startDate, endDate, sortBy, sortDir }
    }).then(result => result);
  },
  show(id) {
    return client.query({
      query: GET_PROMOTION,
      variables: { id }
    }).then(result => result);
  },
  create(payload) {
    return client.mutate({
      mutation: CREATE_PROMOTION,
      variables: { input: payload }
    });
  },
  update(id, payload) {
    return client.mutate({
      mutation: UPDATE_PROMOTION,
      variables: { id, input: payload }
    });
  },
  updateStatusPromotion(params) {
    return client.mutate({
      mutation: UPDATE_PROMOTION_STATUS,
      variables: { id: params.id, is_active: params.is_active }
    });
  },
  delete(id) {
    return client.mutate({
      mutation: DELETE_PROMOTION,
      variables: { id }
    });
  },
  duplicate(payload) {
    const id = payload.id;
    return client.mutate({
      mutation: DUPLICATE_PROMOTION,
      variables: { id }
    });
  },
};
