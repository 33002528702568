import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Input,
  Icon,
  Popconfirm,
  Switch,
  Layout,
  Typography,
  Modal,
  Button,
  message,
  Form,
  Pagination,
  Select,
  Row,
  Col,
  Table,
  DatePicker,
} from 'antd';
import moment from 'moment';

import { alertErr, convertToSlug } from 'utils';
import { DATE_PERIODE_OPTIONS } from 'constants/index';
import PromotionApi from 'api/v2/promotion';

import './style.scss';

const { Header, Content } = Layout;
const { Title } = Typography;
const { Search } = Input;
const { Option } = Select;

export const Promotions = (props) => {
  const { form } = props;
  const { getFieldDecorator } = form;

  const [pagination, setPagination] = useState({
    current_page: 1,
    total_page: 0,
    total_data: 0,
  });
  const [promotions, setPromotions] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [status, setStatus] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [periode, setPeriode] = useState(undefined);
  const [datePeriode, setDatePeriode] = useState({
    start_date: '',
    end_date: '',
  });
  const [sort, setSort] = useState({
    by: '',
    direction: '',
  });

  const handlePromotions = () => {
    setModalVisible(true);
  };

  const hideModal = () => {
    setModalVisible(false);
    form.resetFields('title');
  };

  const getPromotions = async (
    ...args
  ) => {
    try {
      setIsLoading(true);

      const { data } = await PromotionApi.get(...args);

      setPagination({
        ...pagination,
        current_page: data.promotions.current_page,
        total_data: data.promotions.total_data,
        total_page: data.promotions.total_page,
      });

      const PROMOTIONS = data.promotions.promotions;

      setPromotions(PROMOTIONS);
    } catch (error) {
      alertErr(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = () => {
    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          const payload = {
            title: values.title,
            button_is_active: false,
            map_is_active: false,
          };

          await PromotionApi.create(payload);
          message.success('Berhasil menambahkan promo');
          hideModal();
          getPromotions('', '', 1);
        } catch (err) {
          alertErr(err);
        }
      }
    });
  };

  const handleFilter = () =>
    getPromotions(
      keyword,
      status,
      1,
      datePeriode.start_date,
      datePeriode.end_date,
      sort.by,
      sort.direction
    );

  const handleChangePeriode = (val) => {
    setPeriode(val);

    let start_date, end_date;

    switch (val) {
      case 'Today':
        start_date = moment().format('YYYY-MM-DD');
        end_date = moment().format('YYYY-MM-DD');
        break;
      case 'This Week':
        start_date = moment().startOf('isoWeek').format('YYYY-MM-DD');
        end_date = moment().endOf('isoWeek').format('YYYY-MM-DD');
        break;
      case 'Last 7 Days':
        start_date = moment().subtract(6, 'days').format('YYYY-MM-DD');
        end_date = moment().format('YYYY-MM-DD');
        break;
      case 'Last 30 Days':
        start_date = moment().subtract(29, 'days').format('YYYY-MM-DD');
        end_date = moment().format('YYYY-MM-DD');
        break;
      case 'Last 3 Months':
        start_date = moment()
          .subtract(3, 'months')
          .startOf('month')
          .format('YYYY-MM-DD');
        end_date = moment().format('YYYY-MM-DD');
        break;
      case 'This Month':
        start_date = moment().startOf('month').format('YYYY-MM-DD');
        end_date = moment().endOf('month').format('YYYY-MM-DD');
        break;
      case 'This Year':
        start_date = moment().startOf('year').format('YYYY-MM-DD');
        end_date = moment().endOf('year').format('YYYY-MM-DD');
        break;
      default:
        break;
    }

    setDatePeriode({
      ...datePeriode,
      start_date,
      end_date,
    });
  };

  const handlePagination = (v) =>
    getPromotions(
      keyword,
      status,
      v,
      datePeriode.start_date,
      datePeriode.end_date,
      sort.by,
      sort.direction
    );

  const handleDuplicate = async (payload) => {
    try {
      await PromotionApi.duplicate(payload);

      message.success('Berhasil menduplikat promo');
      getPromotions('', '', 1, '', '', sort.by, sort.direction);
    } catch (err) {
      alertErr(err);
    } finally {
      // ...
    }
  };

  const handleSwitched = async (body) => {
    try {

      await PromotionApi.updateStatusPromotion({
        id: body.id,
        is_active: !body.is_active,
      });
      message.success('Berhasil mengubah promo');
      getPromotions('', '', 1, '', '', sort.by, sort.direction);
    } catch (err) {
      alertErr(err);
    } finally {
      setKeyword('');
      setPeriode(undefined);
      setStatus(undefined);
      setDatePeriode({ ...datePeriode, start_date: '', end_date: '' });
    }
  };

  const handleDelete = async (id) => {
    try {
      await PromotionApi.delete(id, { is_deleted: true });
      message.success('Berhasil menghapus promo');
      getPromotions('', '', 1, datePeriode.start_date, datePeriode.end_date);
    } catch (err) {
      alertErr(err);
    } finally {
      setKeyword('');
      setPeriode(undefined);
      setStatus(undefined);
      setDatePeriode({ ...datePeriode, start_date: '', end_date: '' });
    }
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      sorter: true,
    },
    {
      title: 'Preview URL',
      render: (_, record) => {
        const SLUG_GENERATED = `${process.env.REACT_APP_DOMAIN_URL
          }/promotions/${convertToSlug(
            record.slug_is_manual ? record.slug?.trim() : record.title?.trim()
          )}`;

        return (
          <React.Fragment>
            <Typography.Text copyable={{ text: SLUG_GENERATED }}>
              <a
                href={SLUG_GENERATED}
                rel="noopener noreferrer"
                target="_blank"
                style={{ color: '#1890ff' }}>
                {SLUG_GENERATED}
              </a>
            </Typography.Text>
          </React.Fragment>
        );
      },
    },
    {
      title: 'Active',
      dataIndex: 'is_active',
      key: 'is_active',
      width: 130,
      render: (v, record) => (
        <Switch
          checked={v}
          checkedChildren="On"
          unCheckedChildren="Off"
          onChange={() => handleSwitched(record)}
        />
      ),
    },
    {
      title: 'Created Date',
      dataIndex: 'created_at',
      key: 'created_at',
      width: 130,
      render: (_, record) => {
        const created_date = moment(record.created_at).format('DD MMMM YYYY');
        return created_date;
      },
      sorter: true,
    },
    {
      title: 'Updated Date',
      dataIndex: 'updated_at',
      key: 'updated_at',
      width: 130,
      render: (_, record) => {
        const updated_date = moment(record.updated_at).format('DD MMMM YYYY');
        return updated_date;
      },
      sorter: true,
    },
    {
      title: 'Action',
      width: 150,
      render: (v) => {
        return (
          <React.Fragment>
            <div className="action-group">
              <div className="duplicate">
                <div onClick={() => handleDuplicate(v)}>
                  <Icon type="diff" />
                </div>
              </div>
              <div className="edit">
                <Link to={`/cms/promotions/${v.id}`}>
                  <Icon type="edit" />
                </Link>
              </div>
              <div className="edit" style={{ marginLeft: 0 }}>
                <Link to={`/cms/promotions/${v.id}/history`}>
                  <Icon type="history" />
                </Link>
              </div>
              <div className="delete">
                <Popconfirm
                  title="Are you sure？"
                  icon={
                    <Icon type="question-circle-o" style={{ color: 'red' }} />
                  }
                  onConfirm={() => handleDelete(v.id)}>
                  <Icon type="delete" />
                </Popconfirm>
              </div>
            </div>
          </React.Fragment>
        );
      },
    },
  ];

  const onChangeTable = (_p, _f, pSorter) => {
    setSort({
      by: pSorter?.order ? pSorter.field : undefined,
      direction: pSorter?.order
        ? pSorter?.order === 'ascend'
          ? 'asc'
          : 'desc'
        : undefined,
    });

    getPromotions(
      keyword,
      status,
      pagination.current_page,
      datePeriode.start_date,
      datePeriode.end_date,
      pSorter?.order ? pSorter.field : undefined,
      pSorter?.order
        ? pSorter?.order === 'ascend'
          ? 'asc'
          : 'desc'
        : undefined
    );
  };

  useEffect(() => {
    getPromotions('', '', 1, datePeriode.start_date, datePeriode.end_date);

    return () => setPromotions([]);
  }, []);

  return (
    <Layout className="pd-cms-promotions">
      <Header>
        <Title>Manage Promotions</Title>
      </Header>

      <Content id="drag-content">
        <Button
          type="primary"
          style={{ fontSize: '18px' }}
          className="pd-margin-bottom-lg"
          onClick={handlePromotions}>
          Create New Promotion
        </Button>

        <Row gutter={[24, 12]} style={{ marginBottom: 30 }}>
          <Col xs={periode === 'Custom Range' ? 6 : 8}>
            <div className="label" style={{ marginBottom: 8 }}>
              Search
            </div>
            <Search
              placeholder="Search by keyword"
              onChange={(e) => setKeyword(e.target.value)}
              value={keyword}
              allowClear
            />
          </Col>
          <Col xs={periode === 'Custom Range' ? 6 : 8}>
            <div className="label" style={{ marginBottom: 8 }}>
              Status
            </div>
            <Select
              onChange={(v) => setStatus(v)}
              style={{ width: '100%' }}
              placeholder="Select Status"
              allowClear
              value={status}>
              <Option value="true">Active</Option>
              <Option value="false">Not Active</Option>
            </Select>
          </Col>
          <Col xs={periode === 'Custom Range' ? 6 : 8}>
            <div className="label" style={{ marginBottom: 8 }}>
              Period
            </div>
            <Select
              onChange={(v) => handleChangePeriode(v)}
              style={{ width: '100%' }}
              placeholder="Select Period"
              allowClear
              value={periode}>
              {DATE_PERIODE_OPTIONS.map((periode) => (
                <Option key={periode.value} value={periode.value}>{periode.label}</Option>
              ))}
            </Select>
          </Col>
          {periode === 'Custom Range' && (
            <Col xs={periode === 'Custom Range' ? 6 : 8}>
              <div className="label" style={{ marginBottom: 8 }}>
                Date Range
              </div>
              <DatePicker.RangePicker
                format="YYYY-MM-DD"
                value={[
                  datePeriode.start_date
                    ? moment(datePeriode.start_date, 'YYYY-MM-DD')
                    : '',
                  datePeriode.end_date
                    ? moment(datePeriode.end_date, 'YYYY-MM-DD')
                    : '',
                ]}
                onChange={(_, dateString) => {
                  const [start_date, end_date] = dateString;

                  setDatePeriode({
                    ...datePeriode,
                    start_date: start_date
                      ? moment(start_date).format('YYYY-MM-DD')
                      : '',
                    end_date: end_date
                      ? moment(end_date).format('YYYY-MM-DD')
                      : '',
                  });
                }}
              />
            </Col>
          )}
        </Row>

        <Row gutter={[24, 12]} style={{ marginBottom: 30 }}>
          <Col
            xs={{
              span: periode === 'Custom Range' ? 6 : 8,
              offset: periode === 'Custom Range' ? 18 : 16,
            }}>
            <Button
              onClick={handleFilter}
              type="primary"
              ghost
              style={{ width: '100%' }}>
              Apply
            </Button>
          </Col>
        </Row>

        <Row>
          <Col xs={24}>
            <Table
              dataSource={promotions}
              columns={columns}
              pagination={false}
              loading={isLoading}
              onChange={onChangeTable}
            />

            {promotions.length > 0 && (
              <div
                className="pagination"
                style={{ textAlign: 'center', marginTop: 30 }}>
                <Pagination
                  pageSize={10}
                  current={pagination.current_page}
                  onChange={handlePagination}
                  total={pagination.total_data}
                />
              </div>
            )}
          </Col>
        </Row>
      </Content>

      <Modal
        title="Add New Promotion"
        visible={modalVisible}
        onOk={handleSubmit}
        centered="true"
        onCancel={hideModal}>
        <Form.Item>
          {getFieldDecorator('title', {
            rules: [
              { required: true, message: 'Please input promotion title!' },
            ],
            initialValue: '',
          })(<Input placeholder="Input promotion title" />)}
        </Form.Item>
      </Modal>
    </Layout>
  );
};

export const PromotionCreate = Form.create({
  name: 'promotion_create',
})(Promotions);
