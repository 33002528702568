import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';

import ColorPicker from 'rc-color-picker';

import ReactQuill from 'react-quill';

import {
  Button,
  Col,
  Form,
  Icon,
  Input,
  Layout,
  message,
  Radio,
  Row,
  Switch,
  Typography,
} from 'antd';

import _ from 'lodash';
import 'react-quill/dist/quill.snow.css';

import SectionImagesApi from 'api/v2/sectionImages';
import PromotionApi from 'api/v2/promotion';

import { PageSpinner } from 'components/page-spinner';
import { FIELDS } from './constants';
import { convertToSlug, slugRegExp } from 'utils';
import { PdUploader } from 'components/pd-uploader';

import './style.scss';

const { Header, Content } = Layout;
const { Title } = Typography;
const { TextArea } = Input;

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link', 'image'],
    ['clean'],
  ],
};

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
];

export function PromotionForm(props) {
  const [formLayout] = useState();
  const [promotion, setPromotion] = useState({});
  const [color, setColor] = useState([]);
  const [promoSuccess, setPromoSuccess] = useState(false);
  const [mapImages, setMapImages] = useState({
    desktop_map_media_url: '',
    mobile_map_media_url: '',
  });
  const [images, setImages] = useState([]);
  const [uploadImage, setUploadImage] = useState({});
  const [loadingPage, setLoadingPage] = useState(false);

  const { form } = props;
  const { getFieldDecorator, getFieldValue } = form;

  const [loadingButton, setLoadingButton] = useState(false);

  const { id } = props.match.params;

  const generateLink = (index) => {
    const number = images[index][FIELDS.TEMPORARY_WHATSAPP_NUMBER];
    const message = images[index][FIELDS.TEMPORARY_WHATSAPP_MESSAGE].replaceAll(
      ' ',
      '%20'
    );
    const url = 'https://api.whatsapp.com';
    const end_url = `${url}/send?phone=${number}&text=${message}`;

    handleSectionValue(index, end_url, [FIELDS.SECTION_BUTTON_URL]);
  };

  async function getImages(id) {
    console.log('getImages', id);
    setLoadingPage(true);



    try {
      const { data } = await SectionImagesApi.getImagesPromotion(id);
      const sectionImages = data.sectionImagesByPromotionId;

      setImages(sectionImages);
    } catch (err) {
      const errMessage = _.get(err, 'response.data.message');
      message.error(errMessage || 'Cannot connect to the server');
    } finally {
      setLoadingPage(false);
    }
  }

  async function createSectionImage(payload) {
    setLoadingButton(true);

    try {
      await SectionImagesApi.create({ ...payload, promotion_id: Number(id) });
      const { data } = await SectionImagesApi.getImagesPromotion(id);
      const sectionImages = data.sectionImagesByPromotionId;

      setImages(sectionImages);
    } catch (err) {
      const errMessage = _.get(err, 'response.data.message');
      message.error(errMessage || 'Cannot connect to the server');
    } finally {
      setLoadingButton(false);
    }
  }

  async function updateSectionImage(payload) {
    setLoadingButton(true);

    try {
      await SectionImagesApi.update(payload.id, payload);
      getPromotions(id);
      message.success('Section successfully updated');
    } catch (err) {
      const errMessage = _.get(err, 'response.data.message');
      message.error(errMessage || 'Cannot connect to the server');
    } finally {
      setLoadingButton(false);
    }
  }

  async function getPromotions(id) {
    try {
      const { data } = await PromotionApi.show(id);
      const PROMOTION = data.promotion;

      setPromotion(PROMOTION);

      handleMapImages('desktop_map_media_url', PROMOTION.desktop_map_media_url);
      handleMapImages('mobile_map_media_url', PROMOTION.mobile_map_media_url);

      setPromoSuccess(PROMOTION[FIELDS.FORM_SHOW_SUCCESS_MESSAGE]);
    } catch (err) {
      const errMessage = _.get(err, 'response.data.message');
      message.error(errMessage || 'Cannot connect to the server');
    }
  }

  const remove = async (id) => {
    setLoadingButton(true);

    if (images.length > 1) {
      try {
        await SectionImagesApi.delete(id);

        setImages((prevState) => prevState.filter((item) => item.id !== id));

        message.success('Section successfully deleted');
      } catch {
        message.error('Cannot connect to the server');
      } finally {
        setLoadingButton(false);
      }
    }
  };

  const add = async () => {
    const newSectionData = {
      url_link: '',
      url_link_mobile: '',
      section_tag: '',
      section_number:
        images.length > 0 ? images[images.length - 1].section_number + 1 : 1,
    };

    try {
      await createSectionImage(newSectionData);
    } catch (err) {
      const errMessage = _.get(err, 'response.data.message');
      message.error(errMessage || 'Cannot connect to the server');
    }
  };

  const handleSectionValue = (index, value, type) => {
    const temp = [...images];
    temp[index][type] = value;

    setImages(temp);
  };

  const handleSubmit = (e, index) => {
    e.preventDefault();

    form.validateFields((err, values) => {
      if (!err) {
        const sectImage = {
          ...images[index],
          section_tag: values[`${FIELDS.SECTION_TAG}_${index}`],
        };

        updateSectionImage(sectImage);
      }
    });
  };

  const submitPromotion = (e) => {
    e.preventDefault();

    form.validateFields(async (err, values) => {
      const payload = {
        ...values,
        is_active: promotion.is_active,
        ...mapImages,
      };

      if (!err) {
        setLoadingButton(true);

        try {
          await PromotionApi.update(id, payload);

          getPromotions(id);

          message.success('Promotion updated');
        } catch (err) {
          const errMessage = _.get(err, 'response.data.message');
          message.error(errMessage);
        } finally {
          setLoadingButton(false);
        }
      }
    });
  };

  useEffect(() => {
    if (id) {
      getImages(id);
      getPromotions(id);
    }
  }, [id]);

  useEffect(() => {
    getFieldDecorator('keys', {
      initialValue: images,
    });

    images.length > 0 && setColor(images);

    return () => {
      setColor([]);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images]);

  useEffect(() => {
    if (uploadImage.url !== '') {
      const temp = [...images];

      const newArray = temp.map((item, indexArray) => {
        if (indexArray === uploadImage.index) {
          item[uploadImage.purpose] = uploadImage.url;
        }

        return item;
      });

      setImages(newArray);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadImage]);

  // useEffect(() => {
  //   if (FIELDS.SLUG_IS_MANUAL) resetFields('slug');
  // }, []);

  const handleMapImages = (key, value) => {
    setMapImages((prevState) => ({ ...prevState, [key]: value }));
  };

  const changeColor = (value, key, index) => {
    if (value.color) {
      setColor((prevState) => {
        prevState[index][key] = value.color;
        return prevState;
      });

      return value.color;
    }
    return;
  };

  const formItems =
    images.length > 0 &&
    images.map((image, index) => {
      return (
        <Form
          layout={formLayout}
          onSubmit={(event) => handleSubmit(event, index)}
          key={index}>
          <Row>
            <Col xs={9}>
              <Title level={3}>Section {index + 1}</Title>
            </Col>

            <Col xs={{ offset: 12, span: 3 }}>
              {images.length > 1 ? (
                <Button
                  className="btn-full"
                  type="danger"
                  loading={loadingButton}
                  onClick={() => remove(_.get(image, 'id', ''))}>
                  Delete
                </Button>
              ) : null}
            </Col>
          </Row>

          <Row>
            <Col xs={24}>
              <Radio.Group
                onChange={(event) =>
                  handleSectionValue(
                    index,
                    event.target.value,
                    FIELDS.SECTION_SELECTED
                  )
                }
                value={image.section_selected}
                style={{ marginBottom: 10 }}>
                <Radio value={'images'}>Images</Radio>
                <Radio value={'video'}>Video</Radio>
                <Radio value={'text'}>Text</Radio>
                <Radio value={'form'}>Form</Radio>
              </Radio.Group>
            </Col>
          </Row>

          {image[FIELDS.SECTION_SELECTED] === 'images' && (
            <Row gutter={16}>
              <Col xs={12}>
                <Form.Item label="Desktop Picture (1440x920)">
                  <PdUploader
                    purpose="section_images"
                    imgUrl={_.get(image, FIELDS.URL_LINK, '')}
                    promotionId={id}
                    afterUpload={(url) => {
                      setUploadImage({
                        index,
                        purpose: FIELDS.URL_LINK,
                        url,
                      });
                    }}
                  />
                </Form.Item>

                <Form.Item label="Desktop Picture ALT Text">
                  <Input
                    value={_.get(image, FIELDS.URL_LINK_ALT, '')}
                    placeholder="Input desktop picture alt text"
                    onChange={(event) =>
                      handleSectionValue(
                        index,
                        event.target.value,
                        FIELDS.URL_LINK_ALT
                      )
                    }
                  />
                </Form.Item>
              </Col>

              <Col xs={12}>
                <Form.Item label="Mobile Picture">
                  <PdUploader
                    purpose="section_image"
                    imgUrl={_.get(image, FIELDS.URL_LINK_MOBILE, '')}
                    promotionId={id}
                    afterUpload={(url) => {
                      setUploadImage({
                        index,
                        purpose: FIELDS.URL_LINK_MOBILE,
                        url,
                      });
                    }}
                  />
                </Form.Item>

                <Form.Item label="Mobile Picture ALT Text">
                  <Input
                    value={_.get(image, FIELDS.URL_LINK_MOBILE_ALT, '')}
                    placeholder="Input mobile picture alt text"
                    onChange={(event) =>
                      handleSectionValue(
                        index,
                        event.target.value,
                        FIELDS.URL_LINK_MOBILE_ALT
                      )
                    }
                  />
                </Form.Item>
              </Col>

              <Col xs={24}>
                <Form.Item label="Section Tag">
                  {getFieldDecorator(`${FIELDS.SECTION_TAG}_${index}`, {
                    rules: [
                      {
                        pattern: slugRegExp,
                        message:
                          'Must be a valid section tag. ex: example-test',
                      },
                    ],
                    initialValue: image[FIELDS.SECTION_TAG] || '',
                  })(<Input placeholder="Input section tag" />)}
                </Form.Item>
              </Col>

              <Col xs={24}>
                <Form.Item label="Banner Click">
                  <Switch
                    checked={_.get(image, FIELDS.BANNER_CLICK, '')}
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    onChange={(value) =>
                      handleSectionValue(index, value, FIELDS.BANNER_CLICK)
                    }
                  />
                </Form.Item>
              </Col>

              <Col xs={24}>
                <Form.Item label="Banner Click URL">
                  <Input
                    value={_.get(image, FIELDS.BANNER_CLICK_URL, '')}
                    placeholder="Input button url"
                    onChange={(event) =>
                      handleSectionValue(
                        index,
                        event.target.value,
                        FIELDS.BANNER_CLICK_URL
                      )
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          )}

          <Row>
            {image[FIELDS.SECTION_SELECTED] === 'text' && (
              <>
                <Col xs={24}>
                  <Form.Item label="Title">
                    <Input
                      value={_.get(image, FIELDS.SECTION_TITLE, '')}
                      placeholder="Input button url"
                      onChange={(event) =>
                        handleSectionValue(
                          index,
                          event.target.value,
                          FIELDS.SECTION_TITLE
                        )
                      }
                    />
                  </Form.Item>
                </Col>

                <Col xs={24}>
                  <Form.Item label="Sub-Title">
                    <Input
                      value={_.get(image, FIELDS.SECTION_SUB_TITLE, '')}
                      placeholder="Input button url"
                      onChange={(event) =>
                        handleSectionValue(
                          index,
                          event.target.value,
                          FIELDS.SECTION_SUB_TITLE
                        )
                      }
                    />
                  </Form.Item>

                  <Col xs={24}>
                    <Form.Item label="Section Tag">
                      {getFieldDecorator(`${FIELDS.SECTION_TAG}_${index}`, {
                        rules: [
                          {
                            pattern: slugRegExp,
                            message:
                              'Must be a valid section tag. ex: example-test',
                          },
                        ],
                        initialValue: image[FIELDS.SECTION_TAG] || '',
                      })(<Input placeholder="Input section tag" />)}
                    </Form.Item>
                  </Col>
                </Col>
              </>
            )}

            {image[FIELDS.SECTION_SELECTED] === 'video' && (
              <>
                <Col xs={24}>
                  <Form.Item label="Youtube URL">
                    <Input
                      value={_.get(image, FIELDS.SECTION_VIDEO_URL, '')}
                      placeholder="Input button url"
                      onChange={(event) =>
                        handleSectionValue(
                          index,
                          event.target.value,
                          FIELDS.SECTION_VIDEO_URL
                        )
                      }
                    />
                  </Form.Item>
                </Col>

                <Col xs={24}>
                  <Form.Item label="Headline">
                    <TextArea
                      value={_.get(image, FIELDS.SECTION_VIDEO_HEADLINE, '')}
                      placeholder="Input video healine"
                      onChange={(event) =>
                        handleSectionValue(
                          index,
                          event.target.value,
                          FIELDS.SECTION_VIDEO_HEADLINE
                        )
                      }
                    />
                  </Form.Item>
                </Col>

                <Col xs={24}>
                  <Form.Item label="Section Tag">
                    {getFieldDecorator(`${FIELDS.SECTION_TAG}_${index}`, {
                      rules: [
                        {
                          pattern: slugRegExp,
                          message:
                            'Must be a valid section tag. ex: example-test',
                        },
                      ],
                      initialValue: image[FIELDS.SECTION_TAG] || '',
                    })(<Input placeholder="Input section tag" />)}
                  </Form.Item>
                </Col>

                <Col xs={24}>
                  <Form.Item label="Description">
                    <TextArea
                      value={_.get(image, FIELDS.SECTION_VIDEO_DESCRIPTION, '')}
                      placeholder="Input video description"
                      onChange={(event) =>
                        handleSectionValue(
                          index,
                          event.target.value,
                          FIELDS.SECTION_VIDEO_DESCRIPTION
                        )
                      }
                    />
                  </Form.Item>
                </Col>
              </>
            )}

            {image[FIELDS.SECTION_SELECTED] === 'form' && (
              <>
                <Form.Item label="Section Tag">
                  {getFieldDecorator(`${FIELDS.SECTION_TAG}_${index}`, {
                    rules: [
                      {
                        pattern: slugRegExp,
                        message:
                          'Must be a valid section tag. ex: example-test',
                      },
                    ],
                    initialValue: image[FIELDS.SECTION_TAG] || '',
                  })(<Input placeholder="Input section tag" />)}
                </Form.Item>

                <Form.Item label="Form Slug URL">
                  <Input
                    value={_.get(image, FIELDS.SECTION_FORM_SLUG, '')}
                    placeholder="Input form slug url"
                    onChange={(event) =>
                      handleSectionValue(
                        index,
                        event.target.value,
                        FIELDS.SECTION_FORM_SLUG
                      )
                    }
                  />
                </Form.Item>

                <Form.Item label="Form Headline">
                  <Input
                    value={_.get(image, FIELDS.SECTION_FORM_HEADLINE, '')}
                    placeholder="Input form headline"
                    onChange={(event) =>
                      handleSectionValue(
                        index,
                        event.target.value,
                        FIELDS.SECTION_FORM_HEADLINE
                      )
                    }
                  />
                </Form.Item>

                <Form.Item label="Form Description">
                  <ReactQuill
                    placeholder="Input form description"
                    modules={modules}
                    formats={formats}
                    onChange={(value) =>
                      handleSectionValue(
                        index,
                        value,
                        FIELDS.SECTION_FORM_DESCRIPTION
                      )
                    }
                    value={_.get(image, FIELDS.SECTION_FORM_DESCRIPTION)}
                  />
                </Form.Item>

                <Form.Item label="Form Picture (640x640)">
                  <PdUploader
                    purpose="section_images"
                    imgUrl={
                      _.get(image, FIELDS.SECTION_FORM_IMAGE_URL, '') ||
                      image[FIELDS.SECTION_FORM_IMAGE_URL]
                    }
                    promotionId={id}
                    afterUpload={(url) => {
                      setUploadImage({
                        index,
                        purpose: FIELDS.SECTION_FORM_IMAGE_URL,
                        url,
                      });
                    }}
                  />
                </Form.Item>

                <Form.Item label="Form Picture Alt">
                  <Input
                    value={_.get(image, FIELDS.SECTION_FORM_IMAGE_URL_ALT, '')}
                    placeholder="Input form image alt"
                    onChange={(event) =>
                      handleSectionValue(
                        index,
                        event.target.value,
                        FIELDS.SECTION_FORM_IMAGE_URL_ALT
                      )
                    }
                  />
                </Form.Item>

                <Form.Item label="Show Success Message">
                  <Switch
                    checked={_.get(image, FIELDS.SECTION_FORM_SHOW_MESSAGE, '')}
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    onChange={(value) =>
                      handleSectionValue(
                        index,
                        value,
                        FIELDS.SECTION_FORM_SHOW_MESSAGE
                      )
                    }
                  />
                </Form.Item>
              </>
            )}

            {image[FIELDS.SECTION_SELECTED] !== 'form' && (
              <>
                <Col xs={24}>
                  <Form.Item label="Show Section Button">
                    <Switch
                      checked={_.get(
                        image,
                        FIELDS.SECTION_BUTTON_IS_ACTIVE,
                        ''
                      )}
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      onChange={(value) =>
                        handleSectionValue(
                          index,
                          value,
                          FIELDS.SECTION_BUTTON_IS_ACTIVE
                        )
                      }
                    />
                  </Form.Item>
                </Col>

                <Col xs={24}>
                  <Form.Item label="Section Button Text">
                    <Input
                      value={_.get(image, FIELDS.SECTION_BUTTON_TEXT, '')}
                      placeholder="Input button text"
                      onChange={(event) =>
                        handleSectionValue(
                          index,
                          event.target.value,
                          FIELDS.SECTION_BUTTON_TEXT
                        )
                      }
                    />
                  </Form.Item>
                </Col>

                <Col xs={24}>
                  <Form.Item label="Section Button Height">
                    <Input
                      value={_.get(image, FIELDS.SECTION_BUTTON_HEIGHT, '')}
                      placeholder="Input button height"
                      onChange={(event) =>
                        handleSectionValue(
                          index,
                          event.target.value,
                          FIELDS.SECTION_BUTTON_HEIGHT
                        )
                      }
                    />
                  </Form.Item>
                </Col>

                <Col xs={24}>
                  <Form.Item label="Section Button Width">
                    <Input
                      value={_.get(image, FIELDS.SECTION_BUTTON_WIDTH, '')}
                      placeholder="Input button width"
                      onChange={(event) =>
                        handleSectionValue(
                          index,
                          event.target.value,
                          FIELDS.SECTION_BUTTON_WIDTH
                        )
                      }
                    />
                  </Form.Item>
                </Col>

                <Col xs={24}>
                  <Form.Item label="Generate Whatsapp Number">
                    <Input
                      value={_.get(image, FIELDS.TEMPORARY_WHATSAPP_NUMBER, '')}
                      placeholder="Input whatsapp number"
                      onChange={(event) =>
                        handleSectionValue(
                          index,
                          event.target.value,
                          FIELDS.TEMPORARY_WHATSAPP_NUMBER
                        )
                      }
                    />
                  </Form.Item>
                </Col>

                <Col xs={21}>
                  <Form.Item label="Generate Whatsapp Message">
                    <Input
                      value={_.get(
                        image,
                        FIELDS.TEMPORARY_WHATSAPP_MESSAGE,
                        ''
                      )}
                      placeholder="Input whatsapp message"
                      onChange={(event) =>
                        handleSectionValue(
                          index,
                          event.target.value,
                          FIELDS.TEMPORARY_WHATSAPP_MESSAGE
                        )
                      }
                    />
                  </Form.Item>
                </Col>

                <Col xs={3}>
                  <Button
                    style={{ marginTop: 42 }}
                    onClick={() => generateLink(index)}>
                    Generate
                  </Button>
                </Col>

                <Col xs={24}>
                  <Form.Item label="Section Button Url">
                    <Input
                      value={_.get(image, FIELDS.SECTION_BUTTON_URL, '')}
                      placeholder="Input button url"
                      onChange={(event) =>
                        handleSectionValue(
                          index,
                          event.target.value,
                          FIELDS.SECTION_BUTTON_URL
                        )
                      }
                    />
                  </Form.Item>
                </Col>

                <Col xs={24}>
                  <Form.Item label="Section Button Color">
                    <ColorPicker
                      animation="slide-up"
                      onChange={(value) =>
                        changeColor(value, FIELDS.SECTION_BUTTON_COLOR, index)
                      }
                      color={
                        color[FIELDS.SECTION_BUTTON_COLOR] ||
                        image[FIELDS.SECTION_BUTTON_COLOR]
                      }
                    />
                  </Form.Item>
                </Col>

                <Col xs={24}>
                  <Form.Item label="Section Button Font Color">
                    <ColorPicker
                      onChange={(value) =>
                        changeColor(
                          value,
                          FIELDS.SECTION_BUTTON_FONT_COLOR,
                          index
                        )
                      }
                      animation="slide-up"
                      color={
                        color[FIELDS.SECTION_BUTTON_FONT_COLOR] ||
                        image[FIELDS.SECTION_BUTTON_FONT_COLOR]
                      }
                    />
                  </Form.Item>
                </Col>
              </>
            )}

            <Col xs={24}>
              <Button
                htmlType="submit"
                className="btn-full confirm-btn"
                type="primary"
                loading={loadingButton}>
                Confirm Section {index + 1}
              </Button>
            </Col>
          </Row>
        </Form>
      );
    });

  if (loadingPage) {
    return <PageSpinner />;
  }

  return (
    <Layout className="rata-cms-promotions pd-cms-form-1">
      <Header className="rata-cms-promotions-header">
        <Title>
          <Link to="/cms/promotions">
            <Icon type="caret-left" />
          </Link>
          Edit {promotion.title}
        </Title>
      </Header>

      <Content>
        <Form
          onSubmit={(event) => submitPromotion(event)}
          className="pd-admin-create-form">
          <Form.Item label="Title">
            {getFieldDecorator(FIELDS.TITLE, {
              rules: [
                { required: true, message: 'Please input promotion title!' },
              ],
              initialValue: _.get(promotion, FIELDS.TITLE, ''),
            })(<Input placeholder="Input promotion title" />)}
          </Form.Item>

          <Form.Item label="Type Slug" valuePropName="checked">
            <Row type="flex" align="middle">
              {getFieldDecorator(FIELDS.SLUG_IS_MANUAL, {
                valuePropName: 'checked',
                initialValue: _.get(promotion, FIELDS.SLUG_IS_MANUAL, false),
              })(<Switch checkedChildren="On" unCheckedChildren="Off" />)}
              <Title
                level={4}
                style={{ marginLeft: 16, marginBottom: 0, fontSize: 14 }}>
                {getFieldValue(FIELDS.SLUG_IS_MANUAL)
                  ? 'Manually'
                  : 'Auto Assigned'}
              </Title>
            </Row>
          </Form.Item>

          {getFieldValue(FIELDS.SLUG_IS_MANUAL) && (
            <Form.Item label="Slug">
              {getFieldDecorator(FIELDS.SLUG, {
                rules: [
                  { required: true, message: 'Please input promotion slug!' },
                  {
                    pattern: slugRegExp,
                    message: 'Must be a valid slug. ex: example-test',
                  },
                ],
                initialValue: _.get(promotion, FIELDS.SLUG, ''),
              })(<Input placeholder="Input slug promotion" id="input__slug" />)}
            </Form.Item>
          )}

          <Title level={4}>Action Button</Title>

          <Form.Item label="Show Action Button">
            {getFieldDecorator(FIELDS.BUTTON_IS_ACTIVE, {
              valuePropName: 'checked',
              initialValue: _.get(promotion, FIELDS.BUTTON_IS_ACTIVE, ''),
            })(<Switch checkedChildren="Yes" unCheckedChildren="No" />)}
          </Form.Item>

          <Form.Item label="Button Text">
            {getFieldDecorator(FIELDS.BUTTON_TEXT, {
              initialValue: _.get(promotion, FIELDS.BUTTON_TEXT, ''),
            })(<Input placeholder="Input promotion button text" />)}
          </Form.Item>

          <Form.Item label="Button Url">
            {getFieldDecorator(FIELDS.BUTTON_URL, {
              initialValue: _.get(promotion, FIELDS.BUTTON_URL, ''),
            })(<Input placeholder="Input promotion button url" />)}
          </Form.Item>

          <Title level={4}>Header Button</Title>

          <Form.Item label="Konsultasi Gratis Button Url">
            {getFieldDecorator(FIELDS.HEADER_URL, {
              initialValue: _.get(promotion, FIELDS.HEADER_URL, ''),
            })(<Input placeholder="Input konsultasi gratis button url" />)}
          </Form.Item>

          <Title level={4}>WhatsApp</Title>

          <Form.Item label="Show Whatsapp Promotion Button">
            {getFieldDecorator(FIELDS.WHATSAPP_IS_ACTIVE, {
              valuePropName: 'checked',
              initialValue: _.get(promotion, FIELDS.WHATSAPP_IS_ACTIVE, ''),
            })(<Switch checkedChildren="Yes" unCheckedChildren="No" />)}
          </Form.Item>

          <Form.Item label="Whatsapp Promotion Button Text">
            {getFieldDecorator(FIELDS.WHATSAPP_TEXT, {
              initialValue: _.get(promotion, FIELDS.WHATSAPP_TEXT, ''),
            })(<Input placeholder="Input whatsapp button text" />)}
          </Form.Item>

          <Form.Item label="Whatsapp Promotion Button Url">
            {getFieldDecorator(FIELDS.WHATSAPP_URL, {
              initialValue: _.get(promotion, FIELDS.WHATSAPP_URL, ''),
            })(<Input placeholder="Input whatsapp button url" />)}
          </Form.Item>

          <Title level={4}>Maps</Title>

          <Form.Item label="Show Promotion Map">
            {getFieldDecorator(FIELDS.MAP_IS_ACTIVE, {
              valuePropName: 'checked',
              initialValue: _.get(promotion, FIELDS.MAP_IS_ACTIVE, ''),
            })(<Switch checkedChildren="Yes" unCheckedChildren="No" />)}
          </Form.Item>

          <Form.Item label="Maps Title">
            {getFieldDecorator(FIELDS.MAP_TITLE, {
              initialValue: _.get(promotion, FIELDS.MAP_TITLE, ''),
            })(<Input placeholder="Input map title" />)}
          </Form.Item>

          <Form.Item label="Latitude">
            {getFieldDecorator(FIELDS.LATITUDE, {
              initialValue: _.get(promotion, FIELDS.LATITUDE, ''),
            })(<Input placeholder="Input promotion latitude" />)}
          </Form.Item>

          <Form.Item label="Longitude">
            {getFieldDecorator(FIELDS.LONGITUDE, {
              initialValue: _.get(promotion, FIELDS.LONGITUDE, ''),
            })(<Input placeholder="Input promotion longitude" />)}
          </Form.Item>

          <Row gutter={16}>
            <Col xs={12}>
              <Form.Item label="Desktop Map Background Picture (1440x920)">
                <PdUploader
                  purpose={FIELDS.DESKTOP_MAP_MEDIA_URL}
                  imgUrl={
                    _.get(mapImages, FIELDS.DESKTOP_MAP_MEDIA_URL, '') ||
                    promotion[FIELDS.DESKTOP_MAP_MEDIA_URL]
                  }
                  afterUpload={(url) => {
                    handleMapImages([FIELDS.DESKTOP_MAP_MEDIA_URL], url);
                  }}
                />
              </Form.Item>

              <Form.Item label="Desktop Map Background Picture Alt">
                {getFieldDecorator(FIELDS.DESKTOP_MAP_MEDIA_URL_ALT, {
                  initialValue: _.get(
                    promotion,
                    FIELDS.DESKTOP_MAP_MEDIA_URL_ALT,
                    ''
                  ),
                })(<Input placeholder="Input desktop media alt" />)}
              </Form.Item>
            </Col>

            <Col xs={12}>
              <Form.Item label="Mobile Map Background Picture">
                <PdUploader
                  purpose={FIELDS.MOBILE_MAP_MEDIA_URL}
                  imgUrl={
                    _.get(mapImages, FIELDS.MOBILE_MAP_MEDIA_URL, '') ||
                    promotion[FIELDS.MOBILE_MAP_MEDIA_URL]
                  }
                  afterUpload={(url) => {
                    handleMapImages([FIELDS.MOBILE_MAP_MEDIA_URL], url);
                  }}
                />
              </Form.Item>

              <Form.Item label="Mobile Map Background Picture Alt">
                {getFieldDecorator(FIELDS.MOBILE_MAP_MEDIA_URL_ALT, {
                  initialValue: _.get(
                    promotion,
                    FIELDS.MOBILE_MAP_MEDIA_URL_ALT,
                    ''
                  ),
                })(<Input placeholder="Input mobile media alt" />)}
              </Form.Item>
            </Col>
          </Row>

          <Title level={4}>Floating Banner</Title>

          <Form.Item label="Show Floating Banner">
            {getFieldDecorator(FIELDS.IS_SHOW_FLOATING_BANNER, {
              valuePropName: 'checked',
              initialValue: _.get(
                promotion,
                FIELDS.IS_SHOW_FLOATING_BANNER,
                ''
              ),
            })(<Switch checkedChildren="Yes" unCheckedChildren="No" />)}
          </Form.Item>

          {/* <Title level={4}>Footer Newsletter</Title>

          <Form.Item label="Show Footer Newsletter">
            {getFieldDecorator(FIELDS.IS_SHOW_NEWSLETTER, {
              valuePropName: 'checked',
              initialValue: _.get(promotion, FIELDS.IS_SHOW_NEWSLETTER, ''),
            })(<Switch checkedChildren="Yes" unCheckedChildren="No" />)}
          </Form.Item> */}

          <Form.Item>
            <Button
              htmlType="submit"
              className="btn-full"
              type="primary"
              loading={loadingButton}>
              Confirm Promotion
            </Button>
          </Form.Item>
        </Form>

        {formItems}

        <Button
          className="btn-full"
          onClick={() => add()}
          style={{ marginTop: 30 }}>
          <Icon type="plus" /> Add Section
        </Button>
      </Content>
    </Layout>
  );
}

export const PromotionCreateEditForm = Form.create({
  name: 'validate_promnmotion_edit',
})(PromotionForm);

export default withRouter({ PromotionForm });
