import { gql } from '@apollo/client';
import client from '../apolloClient';




const UPDATE_OUR_OFFER = gql`
  mutation UpdateOurOffer($id: String!, $input: OurOfferInput!) {
    updateOurOffer(id: $id, input: $input) {
      id
      title
      title_font_color
      description
      media_url
      media_url_alt
      link_url
      promo_button_text
      promo_button_background_color
      promo_button_font_color
      promo_button_link
      is_active
      is_deleted
      order_level
    }
  }
`;

const CREATE_OUR_OFFER = gql`
  mutation CreateOurOffer($input: OurOfferInput!) {
    createOurOffer(input: $input) {
      id
      title
      title_font_color
      description
      media_url
      media_url_alt
      link_url
      promo_button_text
      promo_button_background_color
      promo_button_font_color
      promo_button_link
      is_active
      is_deleted
      order_level
    }
  }
`;

// Define the query to get all offers
const GET_ALL_OUR_OFFERS_QUERY = gql`
  query GetAllOurOffers {
    getAllOurOffers {
      id
      title
      title_font_color
      description
      media_url
      media_url_alt
      link_url
      promo_button_text
      promo_button_background_color
      promo_button_font_color
      promo_button_link
      is_active
      is_deleted
      order_level
    }
  }
`;

// Define the query to get an offer by ID
const GET_OUR_OFFER_BY_ID_QUERY = gql`
  query GetOurOfferById($id: String!) {
    getOurOfferById(id: $id) {
      id
      title
      title_font_color
      description
      media_url
      media_url_alt
      link_url
      promo_button_text
      promo_button_background_color
      promo_button_font_color
      promo_button_link
      is_active
      is_deleted
      order_level
    }
  }
`;

const UPDATE_OUR_OFFER_STATUS = gql`
  mutation UpdateOurOfferStatus($id: String!, $is_active: Boolean!) {
    updateOurOfferStatus(id: $id, is_active: $is_active) {
      id
      is_active
    }
  }
`;

const BULK_UPDATE_OUR_OFFERS = gql`
  mutation BulkUpdateOurOffers($inputs: [OurOfferInputBulk!]!) {
    bulkUpdateOurOffers(inputs: $inputs) {
      id
      order_level
      is_active
    }
  }
`;

const DELETE_OUR_OFFER = gql`
  mutation softDeleteOurOffer($id: String!) {
    softDeleteOurOffer(id: $id) {
      id
    }
  }
`;

export default {

  get() {
    return client.query({
      query: GET_ALL_OUR_OFFERS_QUERY,
      fetchPolicy: 'network-only',
      variables: { timestamp: Date.now() },
    }).then(result => {
      return result;
    }).catch(error => {
      throw error;
    });
  },

  getBy(id) {
    return client.query({
      query: GET_OUR_OFFER_BY_ID_QUERY,
      variables: { id }
    }).then(result => result);
  },

  create(input) {

    return client.mutate({
      mutation: CREATE_OUR_OFFER,
      variables: { input: input }
    }).then(result => result.data.createOurOffer);
  },

  update(id, payload) {

    return client.mutate({
      mutation: UPDATE_OUR_OFFER,
      variables: {
        id,
        input: payload
      }
    }).then(response => response.data.updateOurOffer);
  },

  updateStatus(id, payload) {
    return client.mutate({
      mutation: UPDATE_OUR_OFFER_STATUS,
      variables: {
        id,
        is_active: payload.is_active
      }
    }).then(response => response.data.updateOurOfferStatus);
  },

  bulkUpdate(payload) {
    return client.mutate({
      mutation: BULK_UPDATE_OUR_OFFERS,
      variables: {
        inputs: payload
      }
    }).then(response => response.data.bulkUpdateOurOffers);
  },

  delete(id) {
    return client.mutate({
      mutation: DELETE_OUR_OFFER,
      variables: { id }
    }).then(response => response.data.deleteOurOffer);
  },
};
